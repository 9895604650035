import { render, staticRenderFns } from "./PlatformNameSelect.vue?vue&type=template&id=3c9e9453&scoped=true"
import script from "./PlatformNameSelect.vue?vue&type=script&lang=js"
export * from "./PlatformNameSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c9e9453",
  null
  
)

export default component.exports